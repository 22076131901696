(<template>
  <form class="info-form"
        @submit.stop.prevent="submitForm">
    <org-number-field :preselected-value="orgNumber"
                      :disabled="shouldBeDisabledImportantFields"
                      :description="orgNumberText"
                      :placeholder="orgNumberText"
                      :validation-name="orgNumberErrorsName"
                      :autofocus="true"
                      :country="country"
                      @fieldvaluechanged="setOrgNumber" />
    <sk-input :preselected-value="orgName"
              :disabled="shouldBeDisabledImportantFields"
              :description="orgNameText"
              :placeholder="orgNameText"
              :validation-name="orgNameErrorsName"
              :margin-bottom="true"
              :icon="false"
              @fieldvaluechanged="setOrgName" />
    <template v-if="fullForm">
      <sk-input :preselected-value="webSite"
                :description="webSiteText"
                :placeholder="webSiteText"
                :margin-bottom="true"
                :icon="false"
                @fieldvaluechanged="setWebSite" />
      <div class="info-form__phone-cont">
        <sk-select :type="'countryCodes'"
                   :preselected-value="phoneCode"
                   :description="phoneCodeText"
                   :default-value="phoneCodeText"
                   :validation-name="phoneCodeErrorsName"
                   :mob-numeric-keyboard="true"
                   class="info-form__code-select"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :preselected-value="phoneNumber"
                  :description="phoneNumberText"
                  :placeholder="phoneNumberText"
                  :validation-name="phoneNumberErrorsName"
                  :icon="false"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  :type="'tel'"
                  class="info-form__number-label"
                  @fieldvaluechanged="setPhoneNumber" />
      </div>
      <sk-input :preselected-value="line1"
                :description="line1Text"
                :placeholder="line1Text"
                :validation-name="line1ErrorsName"
                :margin-bottom="true"
                :icon="false"
                @fieldvaluechanged="setLine1" />
      <sk-input :preselected-value="line2"
                :description="line2Text"
                :placeholder="line2Text"
                :margin-bottom="true"
                :icon="false"
                @fieldvaluechanged="setLine2" />
      <sk-input :preselected-value="county"
                :description="countyText"
                :placeholder="countyText"
                :validation-name="countyErrorsName"
                :margin-bottom="true"
                :icon="false"
                @fieldvaluechanged="setCounty" />
      <div class="info-form__zip-city-cont">
        <sk-input :preselected-value="postcode"
                  :description="postcodeText"
                  :placeholder="postcodeText"
                  :validation-name="postcodeErrorsName"
                  :margin-bottom="true"
                  :icon="false"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="info-form__post-code"
                  @fieldvaluechanged="setPostcode" />
        <sk-input :preselected-value="city"
                  :description="cityText"
                  :placeholder="cityText"
                  :validation-name="cityErrorsName"
                  :margin-bottom="true"
                  :icon="false"
                  class="info-form__city"
                  @fieldvaluechanged="setCity" />
      </div>
      <sk-select :preselected-value="country"
                 :description="countryText"
                 :default-value="countryText"
                 :type="'countries'"
                 :validation-name="countryErrorsName"
                 class="info-form__country-select"
                 @csvaluechanged="setCountry" />
    </template>
    <div v-if="!fullForm"
         class="info-form__img-cont">
      <tt-avatar :avatar="logoUrl"
                 class="info-form__company-logo-cont" />
      <input id="upload-company-logo"
             class="skiwo-input--file-input is-hidden"
             type="file"
             hidden
             @change="catchLogo">
      <label for="upload-company-logo"
             class="sk-btn sk-btn--white info-form__company-logo-btn">{{ $gettext('Upload your company logo (square)') }}</label>
    </div>
    <sk-input v-if="fullForm"
              :preselected-value="adminEmail"
              :description="adminEmailText"
              :placeholder="adminEmailText"
              :validation-name="adminEmailErrorsName"
              :type="'email'"
              :margin-bottom="true"
              :icon="false"
              @fieldvaluechanged="setAdminEmail" />
    <template v-if="!fullForm">
      <!--      <pricing-model class="info-form__pricing-model-cont" />-->
      <template v-if="showPaymentFields">
        <sk-select :items-list="companiesList"
                   :preselected-value="paymentCompany"
                   :description="companyText"
                   :default-value="companyText"
                   :validation-name="paymentCompanyErrorsName"
                   class="info-form__field"
                   @csvaluechanged="setPaymentCompany" />
        <template v-if="showAdditionalPaymentFields">
          <org-number-field :description="norwegianOrgNumberText"
                            :placeholder="norwegianOrgNumberText"
                            :preselected-value="paymentOrgNumber"
                            :country="'Norway'"
                            :validation-name="paymentOrgNumberErrorsName"
                            class="info-form__field"
                            @fieldvaluechanged="setPaymentOrgNumber" />
        </template>
      </template>
      <terms-check :preselected-value="terms"
                   :validation-name="termsErrorsName"
                   class="info-form__terms-checkbox"
                   @checkboxvaluechanged="setTerms" />
      <button class="sk-btn sk-btn--default info-form__submit-btn">{{ $gettext('Upgrade now') }}</button>
    </template>
    <div v-if="fullForm"
         class="info-form__btns-wrapper">
      <button class="sk-btn sk-btn--white info-form__btn info-form__btn--cancel"
              type="button"
              @click="$emit('closemodal')">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default info-form__btn info-form__btn--update"
              type="submit">{{ $gettext('Save') }}</button>
    </div>
  </form>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  // import PricingModel from '@/components/pricing_model/ChoosePricingModel';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';
  import helpers from '@/helpers';

  export default {
    components: {
      // 'pricing-model': PricingModel,
      'org-number-field': OrgNumberField
    },
    props: {
      fullForm: Boolean,
      disabledOrgNumber: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userEmail',
        'userFirstName',
        'userLastName',
        'enterpriseId'
      ]),
      ...mapState('TTEnterpriseInfoStore', {
        orgNumber: (state) => state.form.orgNumber,
        orgNumberErrors: (state) => state.errors.orgNumber.errors,
        orgNumberErrorsName: (state) => state.errors.orgNumber.name,
        orgName: (state) => state.form.orgName,
        orgNameErrors: (state) => state.errors.orgName.errors,
        orgNameErrorsName: (state) => state.errors.orgName.name,
        webSite: (state) => state.form.webSite,
        phoneCode: (state) => state.form.phoneCode,
        phoneCodeErrors: (state) => state.errors.phoneCode.errors,
        phoneCodeErrorsName: (state) => state.errors.phoneCode.name,
        phoneNumber: (state) => state.form.phoneNumber,
        phoneNumberErrors: (state) => state.errors.phoneNumber.errors,
        phoneNumberErrorsName: (state) => state.errors.phoneNumber.name,
        line1: (state) => state.form.line1,
        line1Errors: (state) => state.errors.line1.errors,
        line1ErrorsName: (state) => state.errors.line1.name,
        line2: (state) => state.form.line2,
        county: (state) => state.form.county,
        countyErrors: (state) => state.errors.county.errors,
        countyErrorsName: (state) => state.errors.county.name,
        postcode: (state) => state.form.postcode,
        postcodeErrors: (state) => state.errors.postcode.errors,
        postcodeErrorsName: (state) => state.errors.postcode.name,
        city: (state) => state.form.city,
        cityErrors: (state) => state.errors.city.errors,
        cityErrorsName: (state) => state.errors.city.name,
        country: (state) => state.form.country,
        countryErrors: (state) => state.errors.country.errors,
        countryErrorsName: (state) => state.errors.country.name,
        logoUrl: (state) => state.form.logoUrl,
        logoFile: (state) => state.form.logoFile,
        adminEmail: (state) => state.form.adminEmail,
        adminEmailErrors: (state) => state.errors.adminEmail.errors,
        adminEmailErrorsName: (state) => state.errors.adminEmail.name,
        terms: (state) => state.form.terms,
        termsErrors: (state) => state.errors.terms.errors,
        termsErrorsName: (state) => state.errors.terms.name,
        paymentOrgNumber: ({form}) => form.paymentOrgNumber,
        paymentOrgNumberErrors: ({errors}) => errors.paymentOrgNumber.errors,
        paymentOrgNumberErrorsName: ({errors}) => errors.paymentOrgNumber.name,
        paymentCompany: ({form}) => form.paymentCompany,
        paymentCompanyErrors: ({errors}) => errors.paymentCompany.errors,
        paymentCompanyErrorsName: ({errors}) => errors.paymentCompany.name,
      }),
      orgNumberText() { return this.$gettext('Organisation number*'); },
      orgNameText() { return this.$gettext('Company name*'); },
      webSiteText() { return this.$gettext('Website'); },
      phoneCodeText() { return this.$gettext('Telephone code'); },
      phoneNumberText() { return this.$gettext('Telephone number'); },
      line1Text() { return this.$gettext('Address line 1'); },
      line2Text() { return this.$gettext('Address line 2'); },
      countyText() { return this.$gettext('County*'); },
      postcodeText() { return this.$gettext('Post code*'); },
      cityText() { return this.$gettext('City*'); },
      countryText() { return this.$gettext('Country'); },
      adminEmailText() { return this.$gettext('Email'); },
      companyText() { return this.$gettext('Bookings will be paid by'); },
      norwegianOrgNumberText() { return this.$gettext('Norwegian organisation number*'); },
      showPaymentFields() { return !!this.orgName.trim(); },
      showAdditionalPaymentFields() { return this.paymentCompany === 'someone_else'; },
      companiesList() {
        return [{
          id: 'my_company',
          name: this.orgName
        }, {
          id: 'someone_else',
          name: this.$gettext('Someone else')
        }];
      },
      shouldBeDisabledImportantFields() {
        return !!(this.enterpriseId && !this.$isGodmode()) || this.disabledOrgNumber;
      }
    },
    methods: {
      ...mapMutations('TTEnterpriseInfoStore', [
        'setError',
        'removeOneError',
        'removeErrors',
        'setOrgNumber',
        'setOrgName',
        'setWebSite',
        'setPhoneCode',
        'setPhoneNumber',
        'setLine1',
        'setLine2',
        'setCounty',
        'setPostcode',
        'setCity',
        'setCountry',
        'setLogoUrl',
        'setLogoFile',
        'setAdminEmail',
        'setTerms',
        'setPaymentCompany',
        'setPaymentOrgNumber',
        'resetForm'
      ]),
      ...mapActions('TTEnterpriseInfoStore', [
        'createForm',
        'createEnterprise',
        'updateEnterprise'
      ]),
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      catchLogo(eve) {
        if (eve && eve.target && eve.target.files && eve.target.files[0]) {
          const converterResponse = this.$avatarConverter.convertAvatar(this, eve.target.files[0]);
          if (!(converterResponse.then) && Array.isArray(converterResponse)) {
            this.$store.commit('InfoModalStore/setInfoModal', {text: converterResponse.join(' ')});
          } else {
            converterResponse.then((response) => {
              this.setLogoUrl(response.target.result);
              this.setLogoFile(eve.target.files[0]);
            });
          }
        }
      },
      submitForm() {
        let indicator = false;

        this.removeErrors();

        if (!this.orgNumber.trim()) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Org. number can\'t be blank')
          });
          indicator = true;
        }
        if (!this.orgName.trim()) {
          this.setError({
            fieldName: 'orgName',
            errorText: this.$gettext('Company name can\'t be blank')
          });
          indicator = true;
        }
        if (this.line1 || this.county || this.postcode || this.city || this.country) {
          if (!this.line1.trim() && this.fullForm) {
            this.setError({
              fieldName: 'line1',
              errorText: this.$gettext('Address line 1 can\'t be blank')
            });
            indicator = true;
          }
          if (!this.country.trim() && this.fullForm) {
            this.setError({
              fieldName: 'country',
              errorText: this.$gettext('Country can\'t be blank')
            });
            indicator = true;
          }
          if (!this.county.trim() && this.fullForm) {
            this.setError({
              fieldName: 'county',
              errorText: this.$gettext('County can\'t be blank')
            });
            indicator = true;
          }
          if (!this.postcode.trim() && this.fullForm) {
            this.setError({
              fieldName: 'postcode',
              errorText: this.$gettext('Postcode can\'t be blank')
            });
            indicator = true;
          }
          if (!this.city.trim() && this.fullForm) {
            this.setError({
              fieldName: 'city',
              errorText: this.$gettext('City can\'t be blank')
            });
            indicator = true;
          }
        }
        if (!this.terms && !this.fullForm) {
          this.setError({
            fieldName: 'terms',
            errorText: ''
          });
          indicator = true;
        }
        if (!this.fullForm) {
          if (this.orgName.trim() && !this.paymentCompany) {
            this.setError({
              fieldName: 'paymentCompany',
              errorText: this.$gettext('Please choose option.')
            });
            indicator = true;
          }
          if (this.showAdditionalPaymentFields && !this.paymentOrgNumber.trim()) {
            this.setError({
              fieldName: 'paymentOrgNumber',
              errorText: this.$gettext('Org. number can\'t be blank.')
            });
            indicator = true;
          }
        }

        this.$store.commit('ErrorsStore/setError', {name: this.orgNumberErrorsName, errors: this.orgNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.orgNameErrorsName, errors: this.orgNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.phoneCodeErrorsName, errors: this.phoneCodeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.line1ErrorsName, errors: this.line1Errors});
        this.$store.commit('ErrorsStore/setError', {name: this.countyErrorsName, errors: this.countyErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.countryErrorsName, errors: this.countryErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.postcodeErrorsName, errors: this.postcodeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.cityErrorsName, errors: this.cityErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.adminEmailErrorsName, errors: this.adminEmailErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.termsErrorsName, errors: this.termsErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.paymentCompanyErrorsName, errors: this.paymentCompanyErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.paymentOrgNumberErrorsName, errors: this.paymentOrgNumberErrors});

        if (!indicator) {
          this.sendInfo();
        }
      },
      finishRequest() {
        this.$emit('formcompleted');
        this.resetForm();
        if (!this.fullForm) {
          this.$router.push(this.$makeRoute({name: 'BuyerCompanyInfo'}));
        }
      },
      preselectAdditionalPaymentFields() {
        if (!this.fullForm && !this.showAdditionalPaymentFields) {
          this.setPaymentOrgNumber(this.orgNumber);
        }
      },
      sendInfo() {
        const actionName = this.fullForm ? 'updateEnterprise' : 'createEnterprise';
        this.preselectAdditionalPaymentFields();
        this.$emit('formstarted');
        this.createForm(this.fullForm)
          .then((form) => {
            this[actionName](form)
              .then(() => {
                if (!this.fullForm) {
                  this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid)
                    .then(() => {
                      this.finishRequest();
                    });
                } else {
                  this.finishRequest();
                }
              })
              .catch((error) => {
                const errorData = error.data;
                if (this.fullForm) {
                  this.$emit('stopprogress');
                } else {
                  this.$emit('formcompleted');
                }
                if (errorData?.errors?.admin_email?.includes('Invalid email')) {
                  this.setError({
                    fieldName: 'adminEmail',
                    errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
                  });
                  this.$store.commit('ErrorsStore/setError', {
                    name: this.adminEmailErrorsName,
                    errors: this.adminEmailErrors
                  });
                }
                if (errorData?.errors?.org_number?.length) {
                  let errorText = '';
                  if (errorData.errors?.org_number.includes('Organisation number is invalid')) {
                    errorText = this.$gettext('Organisation number is not valid');
                  }
                  this.setError({fieldName: 'orgNumber', errorText});
                  this.$store.commit('ErrorsStore/setError', {
                    name: this.orgNumberErrorsName,
                    errors: this.orgNumberErrors
                  });
                }
                if (errorData?.errors?.org_number?.length) {
                  let errorText = '';
                  if (errorData.errors.org_number.indexOf('This enterprise is already registered') !== -1) {
                    const template = this.$gettext('Enterprise with the same organisation number has already registered. Please contact us on tel. <a href="tel:%{phone}" class="sk-link js-copy-to-clipboard">%{phone}</a>');
                    errorText = this.$gettextInterpolate(template, {phone: this.$getString('domainData', 'supportPhoneText')});
                  }
                  this.setError({fieldName: 'orgNumber', errorText});
                  this.$store.commit('ErrorsStore/setError', {
                    name: this.orgNumberErrorsName,
                    errors: this.orgNumberErrors
                  });
                }
                if (errorData?.errors?.payment?.org_number?.includes('Organisation number is invalid')) {
                  this.setError({fieldName: 'paymentOrgNumber', errorText: this.$gettext('Organisation number is not valid')});
                  this.$store.commit('ErrorsStore/setError', {
                    name: this.paymentOrgNumberErrorsName,
                    errors: this.paymentOrgNumberErrors
                  });
                }
                this.$scrollToErrors();
              });
          });
      }
    }
  };
</script>

<style>
  .info-form__pricing-model-cont .pricing-model-tile.pricing-model-tile {
    width: 100%;
    max-width: initial;
    margin-bottom: 20px;
  }
</style>

<style scoped>
  .info-form {
    display: block;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    padding: 0;
  }

  .info-form__field {
    margin-bottom: 15px;
  }

  .info-form__pricing-model-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: initial;
  }

  .info-form__country-select {
    margin-bottom: 15px;
  }

  .info-form__terms-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .info-form__code-select,
  .info-form__post-code {
    flex-shrink: 0;
    width: 100px;
    margin-right: 20px;
  }

  .info-form__phone-cont,
  .info-form__zip-city-cont,
  .info-form__img-cont {
    display: flex;
    width: 100%;
  }

  .info-form__img-cont {
    align-items: center;
  }

  .info-form__phone-cont {
    padding-bottom: 20px;
  }

  .info-form__company-logo-btn {
    margin-bottom: 12px;
  }

  .info-form__company-logo-cont {
    width: 100%;
    max-width: 50px;
    height: 50px;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  .info-form__submit-btn {
    margin-bottom: 36px;
  }

  .info-form__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .info-form__btn {
    display: inline-block;
    width: auto;
    padding: 0 25px;
  }

  .info-form__btn:first-child {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    .info-form {
      max-width: none;
    }

    .info-form__pricing-model-cont.info-form__pricing-model-cont {
      max-width: initial;
    }
  }
</style>
